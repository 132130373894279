<template>
  <div class="d-block d-lg-none MobileNavbar">
    <b-container class="py-3">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex">
          <b-button v-b-toggle.nav-bar variant="dark">
            <span class="fa-2x fas fa-bars" />
          </b-button>
          <router-link
            class="text-dark text-decoration-none mx-1 d-flex flex-column justify-content-center"
            :to="{ name: 'HomeIndex' }"
          >
            <div class="suse-font">Happy Jungle</div>
            <div class="text-muted OpenSans-font">
              {{ this.$store.getters['user/currentUserMainStore'].store.name }}
            </div>
          </router-link>
        </div>
        <b-navbar-nav>
          <b-dropdown
            variant="outline-dark"
            class="MobileNavbar__dropdown mb-2"
          >
            <template #button-content>
              <span class="d-none d-md-inline d-lg-inline">
                <span class="fas fa-user mr-1" /> {{ currentUserName }}
              </span>
              <span class="d-sm-inline d-md-none d-lg-none">
                <span class="fas fa-user mr-1" />
              </span>
            </template>
            <b-dropdown-item
              :to="{ name: 'ChangePassword' }"
              active-class="active bg-dark"
            >
              <span class="fas fa-key mr-2" /> Alterar Senha
            </b-dropdown-item>
            <b-dropdown-item variant="danger" @click="logout">
              <span class="fas fa-sign-out-alt mr-2" /> Sair
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-nav>
      </div>
      <b-collapse id="nav-bar" class="mt-2">
        <h3 v-if="isAllowedToSee" class="px-0">Operações</h3>
        <div v-if="isAllowedToSee" class="d-flex flex-wrap gap-1 mb-2">
          <router-link
            active-class="active"
            exact
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'HomeIndex' }"
          >
            <span class="fas fa-user-clock mr-2" />Aluguéis
          </router-link>
          <router-link
            active-class="active"
            exact
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'CheckoutsIndex' }"
          >
            <span class="fas fa-cash-register mr-2" />Caixa
          </router-link>
        </div>

        <hr v-if="isAllowedToSee" />

        <!-- Se for ADMIN ou ADMIN_FRANCHISEE, exibe a seção de Cadastros -->
        <h3 v-if="isAdmin || isAdminFranchisee" class="px-0">Cadastros</h3>

        <!-- Dropdown completo para ADMIN -->
        <div v-if="isAdmin" class="d-flex flex-wrap gap-1 mb-2">
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'ToysIndex' }"
          >
            <span class="fas fa-truck mr-2" />Brinquedos
          </router-link>
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'StoresIndex' }"
          >
            <span class="fas fa-university mr-2" />Lojas
          </router-link>
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'PeriodsIndex' }"
          >
            <span class="fas fa-clock mr-2" />Períodos
          </router-link>
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'ProductsIndex' }"
          >
            <span class="fas fa-shopping-cart mr-2" />Produtos
          </router-link>
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'UsersIndex' }"
          >
            <span class="fas fa-users mr-2" />Usuários
          </router-link>
        </div>

        <!-- Dropdown reduzido para ADMIN_FRANCHISEE -->
        <div v-else-if="isAdminFranchisee" class="d-flex flex-wrap gap-1 mb-2">
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'UsersIndex' }"
          >
            <span class="fas fa-users mr-2" />Usuários
          </router-link>
        </div>

        <hr />
        <h3 class="px-0">Relatórios</h3>

        <h5 class="px-0">Aluguéis</h5>
        <hr />
        <div class="d-flex flex-wrap gap-1 mb-2">
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'ReportRents' }"
          >
            <span class="fas fa-list mr-2" /> Geral
          </router-link>
        </div>
        <div class="d-flex flex-wrap gap-1 mb-2">
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'ReportRentsPerToy' }"
          >
            <span class="fas fa-truck mr-2" /> Por Brinquedo
          </router-link>
        </div>
        <div class="d-flex flex-wrap gap-1 mb-3">
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'ReportRentsPerUser' }"
          >
            <span class="fas fa-user mr-2" /> Por Usuário
          </router-link>
        </div>

        <h5 class="px-0">Vendas</h5>
        <hr />
        <div class="d-flex flex-wrap gap-1 mb-3">
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'ReportProductsPayment' }"
          >
            <span class="fas fa-cash-register mr-2" /> Produtos
          </router-link>
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'ReportPaymentMethods' }"
          >
            <span class="fas fa-cash-register mr-2" /> Formas de Pagamentos
          </router-link>
        </div>

        <h5 class="px-0">Outros</h5>
        <hr />
        <div class="d-flex flex-wrap gap-1 mb-3">
          <router-link
            active-class="active"
            class="btn btn-lg btn-outline-dark d-flex align-items-center justify-content-center flex-1"
            :to="{ name: 'ReportCheckouts' }"
          >
            <span class="fas fa-cash-register mr-2" /> Caixas
          </router-link>
        </div>

        <div class="row gap-1 mt-2">
          <b-col class="px-0 d-flex justify-content-end gap-2">
            <b-btn variant="clear" :to="{ name: 'ChangePassword' }">
              <span class="fas fa-key mr-2" /> Alterar Senha
            </b-btn>
            <b-btn variant="clear text-danger" @click="logout">
              <span class="fas fa-sign-out-alt mr-2" /> Sair
            </b-btn>
          </b-col>
        </div>
      </b-collapse>
    </b-container>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex'

export default {
  name: 'MobileNavbar',
  computed: {
    ...mapState('user', ['currentUserName']),
    ...mapGetters('store', ['isAdmin', 'isAdminFranchisee', 'isShopping']),
    isAllowedToSee() {
      return !this.isShopping
    }
  },
  methods: {
    ...mapActions('auth', ['logout'])
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=SUSE:wght@100..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');

.suse-font {
  font-family: 'SUSE', sans-serif;
  font-size: 3.5vw;
  font-weight: 400;
  font-style: normal;

  @media (max-width: 768px) {
    font-size: 5vw;
  }

  @media (max-width: 480px) {
    font-size: 6vw;
  }
}

.OpenSans-font {
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  font-size: 2vw;
  font-style: normal;

  @media (max-width: 768px) {
    font-size: 3vw;
  }

  @media (max-width: 480px) {
    font-size: 4vw;
  }
}

.MobileNavbar {
  &__dropdown {
    .dropdown-menu {
      @media (max-width: 992px) {
        transform: translate3d(-120px, 38px, 0px) !important;
      }
    }
  }
  .nav-link {
    color: #343a40;
  }
}
</style>
